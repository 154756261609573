
import { ref, defineComponent, computed, onMounted, reactive, onUnmounted } from 'vue'
import InputElement from '@/components/UI/Input.vue'
import Loader from '@/components/UI/Loader.vue'
import { useStore } from '@/store'
import { CompanyActionTypes } from '@/store/company'
import Button from '@/components/UI/Button.vue'
import InfoPopup from '@/components/UI/InfoPopup.vue'
import { MutationTypes } from '@/store/company/mutations'
import { useRouter } from 'vue-router'
import MessageBar from '@/components/UI/MessageBar.vue'
import getCompany from '@/mixins/getCompany'
export default defineComponent({
  name: 'Home',
  setup() {
    const store = useStore()
    const router = useRouter()
    const file = ref('')
    const selectedRow = ref()
    const role = computed(() =>
      store.state.auth.currentUser && store.state.auth.currentUser.roles ? store.state.auth.currentUser.roles[0] : ''
    )
    const roles = computed(() => store.state.auth.currentUser?.roles)
    const companyDetailsState = computed(() => store.state.company.getCompanyById)
    const loader = computed(() => store.state.company.loader)
    const isCompanyUpdated = computed(() => store.state.company.isCompanyUpdated)
    const logoError = computed(() => store.state.company.logoError)
    const companyDetailsErrors = reactive({})
    const companyDetails = reactive({
      name: '',
      email: '',
      phoneNumber: '',
      address: '',
      postalCode: '',
      city: '',
      userAgreementCopyEmail: [],
      quotationRequestEmail: [],
      logo: {}
    })
    const { compId, companyDetailState } = getCompany()
    const initialize = async () => {
      await store.dispatch(CompanyActionTypes.GET_COMPANYBYID, { companyId: compId })
      companyDetails.name = companyDetailsState.value.name
      companyDetails.email = companyDetailsState.value.email
      companyDetails.phoneNumber = companyDetailsState.value.phoneNumber
      companyDetails.address = companyDetailsState.value.address
      companyDetails.postalCode = companyDetailsState.value.postalCode
      companyDetails.city = companyDetailsState.value.city
      if (
        companyDetailsState.value.userAgreementCopyEmail == null ||
        !companyDetailsState.value.userAgreementCopyEmail.length
      ) {
        companyDetails.userAgreementCopyEmail = [{ email: '', type: 'add' }]
      } else {
        companyDetails.userAgreementCopyEmail = companyDetailsState.value.userAgreementCopyEmail.map((item) => {
          return { email: item, type: 'delete' }
        })
        companyDetails.userAgreementCopyEmail.push({ email: '', type: 'add' })
      }

      if (
        companyDetailsState.value.quotationRequestEmail == null ||
        !companyDetailsState.value.quotationRequestEmail.length
      ) {
        companyDetails.quotationRequestEmail = [{ email: '', type: 'add' }]
      } else {
        companyDetails.quotationRequestEmail = companyDetailsState.value.quotationRequestEmail.map((item) => {
          return { email: item, type: 'delete' }
        })
        companyDetails.quotationRequestEmail.push({ email: '', type: 'add' })
      }

      if (companyDetailsState.value.logo)
        file.value = `data:${companyDetailsState.value.logoType};base64,${companyDetailsState.value.logo}`
    }
    onMounted(async () => {
      await initialize()
    })
    const fileChange = (event) => {
      const fileDetails = event.target.files
      companyDetails['logo'] = fileDetails[0]
      file.value = URL.createObjectURL(fileDetails[0])
    }
    const removeImage = () => {
      const blah = document.querySelector('#blah') as HTMLImageElement
      if (blah != null) {
        blah.src = ''
      }
      file.value = ''
      companyDetails['logo'] = {}
      store.dispatch(CompanyActionTypes.DELETE_COMPANYLOGOBYID, { companyId: compId })
      store.commit(MutationTypes.SET_LOGOERROR, '')
      store.state.auth.currentUser.company.logo = ''
      store.state.auth.currentUser.company.logoType = ''
    }
    const validateEmail = (value) => {
      // eslint-disable-next-line no-useless-escape
      const regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}')
      return regex.test(value)
    }
    const validatePhoneNumber = (value) => {
      // validate dutch numbers
      const regex = new RegExp(
        '(^[+][0-9]{2}|^[+][0-9]{2}(0)|^([+][0-9]{2})(0)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9-s]{10}$)'
      )
      return regex.test(value)
    }
    const removeType = (type) => {
      const tempEmail = JSON.parse(JSON.stringify(type))
      const emailArr = []
      tempEmail.forEach((item) => {
        if (item.email) emailArr.push(item.email.toString())
      })
      return emailArr
    }
    const validateSections = () => {
      delete companyDetailsErrors['nameError']
      delete companyDetailsErrors['emailError']
      delete companyDetailsErrors['emailValidationError']
      delete companyDetailsErrors['phoneNumberError']
      delete companyDetailsErrors['phoneNumberValidationError']
      delete companyDetailsErrors['addressError']
      delete companyDetailsErrors['postalCodeError']
      delete companyDetailsErrors['cityError']
      delete companyDetailsErrors['agreementEmailError']
      delete companyDetailsErrors['quotationEmailError']

      if (!companyDetails.name) {
        companyDetailsErrors['nameError'] = 'required'
      }
      if (!companyDetails.email) {
        companyDetailsErrors['emailError'] = 'required'
      }
      if (companyDetails.email && !validateEmail(companyDetails.email)) {
        companyDetailsErrors['emailValidationError'] = 'required'
      }
      if (!companyDetails.phoneNumber) {
        companyDetailsErrors['phoneNumberError'] = 'required'
      }
      if (companyDetails.phoneNumber && !validatePhoneNumber(companyDetails.phoneNumber)) {
        companyDetailsErrors['phoneNumberValidationError'] = 'required'
      }
      if (!companyDetails.address) {
        companyDetailsErrors['addressError'] = 'required'
      }
      if (!companyDetails.postalCode) {
        companyDetailsErrors['postalCodeError'] = 'required'
      }
      if (!companyDetails.city) {
        companyDetailsErrors['cityError'] = 'required'
      }
      if (companyDetails.userAgreementCopyEmail.length) {
        const tmpCheck = removeType(companyDetails.userAgreementCopyEmail)
        tmpCheck.forEach((mail) => {
          if (!validateEmail(mail)) companyDetailsErrors['agreementEmailError'] = 'required'
        })
      }
      if (companyDetails.quotationRequestEmail.length) {
        const tmpCheck = removeType(companyDetails.quotationRequestEmail)
        tmpCheck.forEach((mail) => {
          if (!validateEmail(mail)) companyDetailsErrors['quotationEmailError'] = 'required'
        })
      }
      if (Object.getOwnPropertyNames(companyDetailsErrors).length === 0) {
        return true
      }
    }
    const onInputCompanyDetails = (value, key) => {
      companyDetails[key] = value
    }
    const onColumnInput = (option) => {
      if (option['type'] == 'agreementMail') {
        selectedRow.value = companyDetails.userAgreementCopyEmail
      } else if (option['type'] == 'quotationEmail') {
        selectedRow.value = companyDetails.quotationRequestEmail
      }
      selectedRow.value[option['index']][option.keyName] = option['item']
    }
    const saveCompany = async () => {
      if (validateSections()) {
        store.commit(MutationTypes.SET_LOGOERROR, '')
        const formData = new FormData()
        formData.append('name', JSON.stringify(companyDetails.name))
        formData.append('email', JSON.stringify(companyDetails.email))
        formData.append('phoneNumber', JSON.stringify(companyDetails.phoneNumber))
        formData.append('address', JSON.stringify(companyDetails.address))
        formData.append('postalCode', JSON.stringify(companyDetails.postalCode))
        formData.append('city', JSON.stringify(companyDetails.city))
        formData.append('logo', companyDetails.logo as File)
        formData.append('userAgreementCopyEmail', JSON.stringify(removeType(companyDetails.userAgreementCopyEmail)))
        formData.append('quotationRequestEmail', JSON.stringify(removeType(companyDetails.quotationRequestEmail)))
        formData.append('_method', 'PUT')
        await store.dispatch(CompanyActionTypes.UPDATE_COMPANYBYID, { companyId: compId, body: formData })
        await initialize()
        store.state.auth.currentUser.company.logo = companyDetailsState.value.logo
        store.state.auth.currentUser.company.logoType = companyDetailsState.value.logoType
      }
    }
    const discardCompany = () => {
      router.push({ name: 'Employees' })
    }
    const closePopup = () => {
      store.commit(MutationTypes.SET_ISCOMPANYUPDATED, false)
    }
    const isAdmin = computed(() => {
      return roles.value.includes('ROLE_COMPANY_ADMIN') || roles.value.includes('ROLE_SUPER_ADMIN')
    })
    const addRow = (item) => {
      if (item['type'] == 'agreementMail') {
        selectedRow.value = companyDetails.userAgreementCopyEmail
        selectedRow.value.push({
          email: '',
          type: 'add'
        })
      } else if (item['type'] == 'quotationEmail') {
        selectedRow.value = companyDetails.quotationRequestEmail
        selectedRow.value.push({
          email: '',
          type: 'add'
        })
      }
      selectedRow.value[item['index']]['type'] = 'delete'
    }
    const deleteRow = (item) => {
      if (item['type'] == 'agreementMail') {
        selectedRow.value = companyDetails.userAgreementCopyEmail
      } else if (item['type'] == 'quotationEmail') {
        selectedRow.value = companyDetails.quotationRequestEmail
      }
      selectedRow.value.splice(item['index'], 1)
    }
    onUnmounted(() => {
      store.commit(MutationTypes.SET_LOGOERROR, '')
    })
    return {
      file,
      fileChange,
      removeImage,
      role,
      companyDetails,
      loader,
      saveCompany,
      discardCompany,
      onInputCompanyDetails,
      companyDetailsErrors,
      isCompanyUpdated,
      closePopup,
      logoError,
      roles,
      isAdmin,
      companyDetailState,
      onColumnInput,
      addRow,
      deleteRow
    }
  },
  components: {
    InputElement,
    Loader,
    Button,
    InfoPopup,
    MessageBar
  }
})
